import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import SliceZone from '../components/SliceZone';

export const B2B = ({data}) => {
    if (!data) return null;
    const header = data.prismicHeaderitem || {};
    const footer = data.prismicFooteritem || {};
    const {lang, type, url} = header || {};
    const alternateLanguages = header.alternate_languages || [];
    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    };

    const b2b = data.prismicB2B || {};

    return (
        <Layout activeDocMeta={activeDoc} body={header} bodyFooter={footer} fullPage={false}>
            <SliceZone slices={b2b.data.body} />
        </Layout>
    );
};

export const query = graphql`
    query($lang: String) {
        prismicB2B(lang: {eq: $lang}) {
            url
            type
            lang
            data {
                body {
                    ... on PrismicB2bBodyHeroSection {
                        id
                        slice_label
                        slice_type
                        primary {
                            background_image {
                                url
                            }
                            button {
                                text
                            }
                            button_link {
                                url
                            }
                            subtitle {
                                text
                                raw
                            }
                            title {
                                text
                            }
                        }
                    }
                    ... on PrismicB2bBodyLargetestimonialSection {
                        id
                        slice_label
                        slice_type
                        primary {
                            button1 {
                                text
                            }
                            button_link {
                                url
                            }
                            section_image {
                                url
                            }
                            section_name {
                                text
                            }
                            section_number {
                                text
                            }
                            text1 {
                                text
                            }
                            text2 {
                                text
                            }
                            text_button {
                                text
                            }
                            title1 {
                                text
                            }
                        }
                    }
                    ... on PrismicB2bBodyHoverCardSection {
                        id
                        slice_label
                        slice_type
                        items {
                            card_description {
                                text
                            }
                            card_hover_description {
                                text
                            }
                            card_hover_item1 {
                                text
                            }
                            card_hover_item2 {
                                text
                            }
                            card_hover_item3 {
                                text
                            }
                            card_hover_title {
                                text
                            }
                            card_image {
                                url
                            }
                            card_title {
                                text
                            }
                            show_more_text {
                                text
                            }
                        }
                        primary {
                            above_cta_text {
                                text
                            }
                            cta_text {
                                text
                            }
                            section_title {
                                text
                            }
                            section_number {
                                text
                            }
                            title {
                                text
                            }
                        }
                    }
                    ... on PrismicB2bBodyNumberslidersection {
                        id
                        slice_type
                        slice_label
                        primary {
                            description {
                                text
                            }
                            section_number {
                                text
                            }
                            section_title {
                                text
                            }
                            title {
                                text
                            }
                        }
                        items {
                            slide_number
                            slider_text {
                                text
                            }
                        }
                    }
                    ... on PrismicB2bBodyTextureSection {
                        id
                        slice_type
                        slice_label
                        primary {
                            buttontext {
                                text
                            }
                            emailtext {
                                text
                            }
                            title {
                                text
                            }
                        }
                    }
                    ... on PrismicB2bBodyOutstandingProducts {
                        id
                        primary {
                            cta_text {
                                text
                            }
                            button_link {
                                url
                            }
                            section_number {
                                text
                            }
                            section_title {
                                text
                            }
                            title {
                                text
                            }
                            background_image {
                                url
                            }
                        }
                        slice_label
                        slice_type
                    }
                    ... on PrismicB2bBodyBlackFullpageSlider {
                        id
                        slice_label
                        slice_type
                        primary {
                            cta_text {
                                text
                            }
                            description {
                                text
                            }
                            item1 {
                                text
                            }
                            item2 {
                                text
                            }
                            item3 {
                                text
                            }
                            item4 {
                                text
                            }
                            item5 {
                                text
                            }
                            item6 {
                                text
                            }
                            item7 {
                                text
                            }
                            section_number {
                                text
                            }
                            section_title {
                                text
                            }
                            title {
                                text
                            }
                        }
                        items {
                            sublabel {
                                text
                            }
                            title {
                                text
                            }
                        }
                    }
                    ... on PrismicB2bBodyBlueSection {
                        id
                        slice_label
                        slice_type
                        primary {
                            section_number {
                                text
                            }
                            section_title {
                                text
                            }
                            subtitle {
                                text
                            }
                            subtitle_subtext {
                                text
                            }
                            title {
                                text
                            }
                            title_subtext {
                                text
                            }
                        }
                    }
                    ... on PrismicB2bBodyOurSolutions {
                        id
                        slice_label
                        slice_type
                        primary {
                            section_number {
                                text
                            }
                            section_title {
                                text
                            }
                            title {
                                text
                            }
                        }
                    }
                    ... on PrismicB2bBodyTileSection {
                        id
                        slice_label
                        slice_type
                        items {
                            text {
                                text
                            }
                        }
                        primary {
                            description {
                                text
                            }
                            section_number {
                                text
                            }
                            section_title {
                                text
                            }
                            title {
                                text
                            }
                            whitelabel_description {
                                text
                            }
                            whitelabel_title {
                                text
                            }
                        }
                    }
                }
            }
        }
        prismicHeaderitem(lang: {eq: $lang}) {
            lang
            type
            url
            alternate_languages {
                lang
                type
            }
            data {
                button {
                    text
                }
                item1 {
                    text
                }
                item2 {
                    text
                }
                item3 {
                    text
                }
                item4 {
                    text
                }
                item5 {
                    text
                }
                item6 {
                    text
                }
                solutions {
                    solution_name {
                        text
                    }
                }
                cookie_consent {
                    raw
                }
            }
        }
        prismicFooteritem(lang: {eq: $lang}) {
            url
            type
            lang
            alternate_languages {
                lang
                type
            }
            data {
                about {
                    text
                }
                allrights {
                    text
                }
                item1 {
                    text
                }
                item2 {
                    text
                }
                item3 {
                    text
                }
                item4 {
                    text
                }
                item5 {
                    text
                }
                item6 {
                    text
                }
                item7 {
                    text
                }
                privacy {
                    text
                }
                terms {
                    text
                }
                clientinformation {
                    text
                }
                impressum {
                    text
                }
            }
        }
    }
`;

export default B2B;
